<template>
    <modal>
        <form @submit.prevent="checkForm" method="post" class="w-400 mw-full">
            <div class="form-group">
                <label for="name" class="required">Name</label>
                <div class="invalid-feedback" v-if="errors.name">
                    <ul>
                        <li>This field is required.</li>
                    </ul>
                </div>
                <input v-model="form.name" type="text" class="form-control" id="name" placeholder="Name">
            </div>
            <div class="form-group">
                <label for="color" class="required">Color</label>
                <div class="invalid-feedback" v-if="errors.color">
                    <ul>
                        <li>This field is required.</li>
                    </ul>
                </div>
                <input v-model="form.color" type="color" class="form-control" id="color" placeholder="Color">
            </div>
            <div class="text-right mt-20">
                <a data-dismiss="modal" class="btn mr-5" role="button" aria-label="Close">Close</a>
                <button type="submit" class="btn btn-primary" role="button">Submit</button>
            </div>
        </form>
    </modal>
</template>

<script>
import Modal from "../../components/Modal";
import Toast from "../../mixins/Toast";
import * as halfmoon from 'halfmoon';

export default {
    name: 'add-modal',
    components: { Modal },
    mixins: [ Toast ],
    emits: [ 'added' ],
    data() {
        return {
            form: {
                name: '',
                color: '',
            },
            errors: {
                name: false,
                color: false,
            },
        }
    },
    methods: {
        submitForm() {
            this.$http.post('api/legend/add', this.form).then((response) => {
                this.ToastSuccess(response.data);
                halfmoon.toggleModal('add-new-legend-modal');
                this.resetForm();
                this.$emit('added');
            });
        },
        checkForm: function () {
            if (this.form.name != '' && this.form.color) {
                this.submitForm();
                return;
            }

            this.resetErrors();

            if (!this.form.name) {
                this.errors.name = true;
            }
            if (!this.form.color) {
                this.errors.color = true;
            }
        },
        resetErrors() {
            this.errors.name = false;
            this.errors.color = false;
        },
        resetForm() {
            this.form.name = '';
            this.form.color = '';
        },
    }
}
</script>