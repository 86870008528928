<template>
    <layout>
        <div>
            <div class="d-flex justify-content-between">
                <h5>Manage Legends</h5>
                <div class="mt-15 d-flex justify-content-between">
                    <input v-model="search" @keyup="getList()" class="form-control form-control-sm mr-10" placeholder="Search">
                    <a data-toggle="modal" data-target="add-new-legend-modal" role="button" class="btn btn-primary btn-sm">
                        <i class="fa fa-plus"></i>&nbsp; Add New
                    </a>
                </div>
            </div>
            <div>
                <table class="table table-hover table-outer-bordered font-size-12">
                    <thead>
                        <tr>
                            <th width="40%">📑 NAME</th>
                            <th width="40%">🔴 COLOR</th>
                            <th width="20%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="legend in data.data" :key="legend.id">
                            <td>{{ legend.name }}</td>
                            <td><div style="width: 100px; height:20px;" :style="{background: legend.color}" >&nbsp;</div></td>
                            <td class="text-right font-size-12">
                                <edit-button class="mr-5" :id="legend.id" v-on:editable-data="updateEditableLegend"></edit-button>
                                <delete-button :id="legend.id" v-on:deleted="getList"></delete-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end">
                    <pagination
                        v-if="data.links && data.links.length > 3"
                        :links="data.links"
                        :first_page_url="data.first_page_url"
                        :last_page_url="data.last_page_url"
                        :prev_page_url="data.prev_page_url"
                        :next_page_url="data.next_page_url"
                        v-on:click="getList"
                    ></pagination>
                </div>
            </div>
            <add-modal id="add-new-legend-modal" title="Add New Legend" v-on:added="getList"></add-modal>
            <edit-modal id="edit-legend-modal" title="Edit Legend" :data="editData" v-on:updated="getList"></edit-modal>
        </div>
    </layout>
</template>

<script>
import Pagination from '../../components/Pagination';
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import EditButton from "./EditButton";
import DeleteButton from "./DeleteButton";

export default {
    components: {
        Pagination,
        AddModal,
        EditModal,
        EditButton,
        DeleteButton
    },
    data() {
        return {
            data: [],
            search: '',
            editData: {},
        }
    },
    created() {
        this.getList();
    },
    methods: {
        getList(link = null) {
            let newLink = link || `api/legends?page=1`;
            this.$http.get(`${newLink}&search=${this.search}`).then((response) => {
                this.data = response.data.list;
            });
        },
        updateEditableLegend(data) {
            this.editData = data;
        },
    }
}
</script>