<template>
    <a @click="deleteItem(id)" class="btn btn-sm btn-danger">
        <i class="fa fa-trash"></i>
    </a>
</template>

<script>
import Swal from "../../mixins/Swal.js";
import Toast from "../../mixins/Toast";

export default {
    name: 'delete-button',
    props: [ 'id' ],
    mixins: [Toast, Swal],
    emits: [ 'deleted' ],
    methods: {
        async deleteItem(id) {
            let isConfirmed = await this.SwalDelete(
                'Are you sure?',
                'Are you sure you want to delete this legend?'
            );

            if (isConfirmed) {
                this.$http.delete(`api/legend/delete/${id}`).then((response) => {
                    this.ToastSuccess(response.data);
                    this.$emit('deleted');
                });
            }
        }
    }
}
</script>